<template>
  <div class="vip_buy_warp">
    <van-overlay :show="show">
      <div class="vip_buy_box">
        <p>您还不是会员哟～</p>
        <p>成功邀请好友,可获得会员权益</p>
        <p>你的观看次数不足</p>
        <div class="btn_box flex-center-between">
          <div class="btn openVip" @click="$router.push('/memberCentre?t=vip')">开通VIP</div>
          <div class="btn share" @click="$router.push('/sharePromote')">邀请分享</div>
        </div>
        <div class="close" @click="$emit('close')">
          <svg-icon icon-class="close4" />
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Overlay } from 'vant';

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  components: {
    [Overlay.name]: Overlay,
  },
};
</script>
<style lang="scss" scoped>
.vip_buy_warp {
  /deep/ .van-overlay {
    z-index: 9999;
    display: flex;
    justify-content: center;
    // align-items: center;
    padding-top: 80px;
  }

  .vip_buy_box {
    height: 410px;
    width: 288px;
    background: url('./../../assets/png/buyVipBg.png') no-repeat;
    background-size: 100% 100%;
    text-align: center;
    font-size: 14px;
    p:nth-of-type(1) {
      margin-top: 204px;
      font-weight: 600;
      color: rgb(247, 74, 130);
    }
    p:nth-of-type(2) {
      color: rgb(102, 102, 102);
      margin-top: 7px;
    }
    p:last-of-type {
      color: rgb(153, 153, 153);
      margin-top: 61px;
    }
    .btn_box {
      margin-top: 11px;
      padding: 0 24px;
      .btn {
        height: 38px;
        width: 113px;
        border-radius: 10px;
        text-align: center;
        line-height: 38px;
        font-size: 14px;
      }
      .openVip {
        border: 1.5px solid rgb(247, 86, 130);
        color: rgb(247, 86, 130);
      }
      .share {
        color: white;
        background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
      }
    }
    .close {
      font-size: 32px;
      margin-top: 40px;
    }
  }
}
</style>
